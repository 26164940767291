import * as React from 'react'
import Footer from '../../components/footer/Footer';
//import logo from '../../logo.svg';
import { User } from './interfaces/User';
import QueueListener from './QueueListener';
import './UserUI.css';

interface IState {
	isMenuToggled: boolean;
	responseCode: number;
	responseMessage: string;
	sideMenuSelection: string;
	userDataLoaded: boolean;
}

interface InterfaceProps {}

class UserUI extends React.Component<InterfaceProps, IState> {
	//public Auth: AuthService;
	public state: IState;
	public currentUser: User;

	public listenersState = {
		queueListeners: [
			{ id: "email.q", title: 'Email Queue Listener', url: 'https://eq-listener.cimacorpnotification.com', qurl: 'https://qadmin.cimacorpapi.com'},
			{ id: "crmLoginEmail.q.q", title: 'CRM Login Queue Listener', url: 'https://jms.cimacorpapi.com/crm-login-queue-consumer', qurl: 'https://qadmin.cimacorpapi.com'},
			{ id: "constantcontact.q", title: 'ConstantContact Queue Listener', url: 'https://jms.cimacorpapi.com/cc-queue-consumer', qurl: 'https://qadmin.cimacorpapi.com'},
			{ id: "crmLead.q", title: 'ACC Sales Lead Queue Listener', url: 'https://jms.cimacorpapi.com/acc-queue-consumer', qurl: 'https://qadmin.cimacorpapi.com'},
//			{ id: "crmLead.q", title: 'Sales Lead Queue Listener', url: 'https://crmq-listener.cimacorpnotification.com', qurl: 'https://qadmin.cimacorpapi.com'},
//			{ id: "crhLead.q", title: 'old CRH Sales Lead Queue Listener', url: 'https://crhq-listener.cimacorpnotification.com', qurl: 'https://qadmin.cimacorpapi.com'},
			{ id: "crhLead.q", title: 'CRH Sales Lead Queue Listener', url: 'https://jms.cimacorpapi.com/crh-queue-consumer', qurl: 'https://qadmin.cimacorpapi.com'},
			{ id: "bdxLead.q", title: 'BDX Lead Queue Listener', url: 'https://bdxq-listener.cimacorpnotification.com', qurl: 'https://qadmin.cimacorpapi.com'},
			{ id: "insLead.q", title: 'Ins Queue Listener', url: 'https://jms.cimacorpapi.com/ins-queue-consumer', qurl: 'https://qadmin.cimacorpapi.com'},
			{ id: "mhLead.q", title: 'MFD Queue Listener', url: 'https://mhq-listener.cimacorpnotification.com', qurl: 'https://qadmin.cimacorpapi.com'},
			{ id: "a1-housing.q", title: 'A1 Housing Queue Listener', url: 'https://jms.cimacorpapi.com/a1-housing', qurl: 'https://qadmin.cimacorpapi.com'}
//			{ id: "dev-crmLead.q", title: 'DEV CRM Sales Lead Queue Listener', url: 'https://jms.cimacorpapi.com/dev-crm-queue-consumer', qurl: 'https://dev2-qadmin.cimacorpapi.com'}
      /* { id: "fbxt.q", title: 'FB to XT Queue Listener', url: 'https://fbxtq-listener.cimacorpnotification.com', qurl: 'https://dev-qadmin.cimacorpapi.com'},
      { id: "insLead.q", title: 'Tower Hill Ins Lead Queue Listener', url: 'https://th-insq-listener.cimacorpnotification.com', qurl: 'https://dev-qadmin.cimacorpapi.com'},
			{ id: "insEpicLead.q", title: 'Ins to Epic Queue Listener', url: 'https://epic-insq-listener.cimacorpnotification.com', qurl: 'https://dev-qadmin.cimacorpapi.com'} */
		]
	}

	constructor(props: InterfaceProps) {
		super(props);
		this.state = {
			isMenuToggled: false,
			responseCode: 200,
			responseMessage: "System is online",
			sideMenuSelection: "SHOW_FEEDS",
			userDataLoaded: false,
		}

		// event bindings
		this.updateLoginState = this.updateLoginState.bind(this);
		this.toggleMenuState = this.toggleMenuState.bind(this);
	}

	public componentDidMount() {
	}

	public updateLoginState() {
		this.setState({
			// isLoggedIn: !this.state.isLoggedIn
		});
	}

	public toggleMenuState() {
		this.setState((prevState) => {
			return {isMenuToggled: !prevState.isMenuToggled};
		});
	}


	public render() {
		// const { userDataLoaded } = this.state;
		return(
			<div style={{backgroundColor: "#333333", color: "#dddddd"}}>

				<div style={{margin: "2rem 0"}}>
				{this.listenersState.queueListeners.map(queueListener => 
					<QueueListener key={queueListener.id} id={queueListener.id} title={queueListener.title} listenerUrl={queueListener.url} queueUrl={queueListener.qurl}/>
					)}
				</div>

				<div style={{margin: "0 20px"}}>
				<div style={{display: "flex", alignItems: "center", margin: "20px"}}>
					<p style={{backgroundColor: "#00cc00", color: "black", borderRadius:"15px", border:"1px solid", padding:"5px", width: "18px", left:"10px", textAlign:"center"}}>?</p>
					<p style={{marginLeft:"20px"}}>Success / queue message count OK</p>
				</div>
				<div style={{display: "flex", alignItems: "center", margin: "20px"}}>
					<p style={{backgroundColor: "#ff9900", color: "black", borderRadius:"15px", border:"1px solid", padding:"5px", width: "18px", left:"10px", textAlign:"center"}}>?</p>
					<p style={{marginLeft:"20px"}}>Warning / queue message count needs attention!</p>
				</div>
				<div style={{display: "flex", alignItems: "center", margin: "20px"}}>
					<p style={{backgroundColor: "#ff3300", color: "black", borderRadius:"15px", border:"1px solid", padding:"5px", width: "18px", left:"10px", textAlign:"center"}}>?</p>
					<p style={{marginLeft:"20px"}}>ERROR - text is the error code!</p>
				</div>
				</div>
        
				<Footer />
			</div>
		)
	}
}

export default UserUI;


